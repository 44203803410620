// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { SelectedCard } from "./components/ChatbotSidePanelController";
import { QuestionType } from "../../automaticformcreation/src/AutomaticFormCreationController.web";

export const configJSON = require("./config");

const visitorsLandOnSite = "when_visitors_land_on_the_site";

type GeneralSettings = {
  data: {
    id: string;
    type: "setting";
    attributes: {
      practice_association: string[];
      when_should_bot_initiate_chat: {
        mobile: string[];
        desktop: string[];
      };
      response_interval: null | number;
      what_user_action_should_the_bot_allow: {
        device: string[];
      };
      chatbot_url: null | string;
      chatbot_id: number;
      edatt: boolean;
      qr_code: null | string;
    };
  };
};

type DesignSettings= {
  data: {
    id: string;
    type: "customise_interface";
    attributes: {
      id: number;
      color: null | string;
      header_color: string;
      label: string;
      text_size: null | string;
      background_color: string;
      contrast: null | string;
      catalogue_id: null | string;
      widget_type: string;
      practice_association: string[];
      chatbot_name: string;
      widget_url: null | string;
    };
  };
};

export type ConnectorCardLink = {
  id: number;
  link_name: string;
  link_type: string;
  open_link: string;
  icon_file: string | null;
  link_url: string | null;
  destination: string;
  connector_icon_image_link: string | null;
}

type FormQuestion = {
  id: number;
  question: string;
  question_type: QuestionType;
  position: number;
  options: string[];
  bx_block_custom_forms_custom_form_id: number;
  created_at: string;
  updated_at: string;
};

type EmbeddedForm = {
  id: number;
  questions: FormQuestion[];
};

type UserGuide = {
  id: number;
  title: string;
  content: string;
};

export type ChatResponse = {
  id: number;
  message: string;
  video_link: string | null;
  card_type: SelectedCard;
  card_wait_time: number;
  option: string[];
  minimum_selection: number | null;
  maximum_selection: number | null;
  connector_card_links: ConnectorCardLink[];
  embeded_forms: EmbeddedForm[];
  user_guides: UserGuide[];
  video_data: {
    title: string;
    description: string | null;
    thumbnail_url: string;
  } | null;
};

type ChatAttributes = {
  id: number;
  chatbot_id: number;
  query: string | null;
  response: ChatResponse;
};

type ChatData = {
  id: string;
  type: string;
  attributes: ChatAttributes;
};

export type FormQuestionWithAnswer = {
  id: number;
  question: string;
  answer: string;
}

type ChatMessages = {
  id: number;
  message: string;
  cardType?: SelectedCard;
  formQuestionsWithAnswers?: Array<FormQuestionWithAnswer>;
}

export type ChatMessageList = {
  sender: "bot" | "user";
  messages: Array<ChatMessages>;
  reset?: boolean;
}


export interface Props {
  navigation: any;
  id: string;
}

interface S {
  initialOpen: boolean;
  isChatOpen: boolean;
  token: string;
  userAction: string[];
  widgetType: string;
  botIcon: string | null;
  chatHeaderLabel: string;
  chatHeaderBackgroundColor:string;
  chatBackgroundColor: string;
  loading: boolean;
  resonseInterval: number | null;
  botName: string;
  chatLoading: boolean;
  hasErrorInChatResponse: boolean;
  botChatDetailMap: Map<number, ChatResponse>;
  chatMessageLists: ChatMessageList[];
}

interface SS {
  id: any;
}

export default class EndUserChatBotController extends BlockComponent<
  Props,
  S,
  SS
> {
  chatbotDetailCallId: string = "";
  userChatCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationScreenNameMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      initialOpen: false,
      isChatOpen: false,
      token: "",
      userAction: [],
      widgetType: "chat",
      botIcon: null,
      chatHeaderBackgroundColor: "#51ABB3",
      chatHeaderLabel: "Max Support Chatbot",
      chatBackgroundColor: "#FFFFFF",
      loading: false,
      botName: "",
      resonseInterval: 0,
      chatLoading: false,
      hasErrorInChatResponse: false,
      botChatDetailMap: new Map(),
      chatMessageLists: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleRestApiResponse(message)
    }
  }

  handleRestApiResponse = (message: Message) => {
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

    if(this.chatbotDetailCallId === messageId) {
      responseHandlers[this.chatbotDetailCallId] = this.handleChatbotDetailResponse
    }

    if(this.userChatCallId === messageId) {
      responseHandlers[this.userChatCallId] = this.handleInitialUserChatsResponse
    }
    
    const handler = responseHandlers[messageId]
    if(handler) {
      handler(message)
    }
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token, loading: true})
    this.fetchChatbotDetails(token)
  }

  scrollToBottom = () => {
    const container = document.getElementById("chat-popup-container")!
    container.scrollTop = container?.scrollHeight
  }

  fetchChatbotDetails = (token: string) => {
    const botId = this.props.navigation.getParam("navigationBarTitleText")
    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))

    const header = {
      "Content-Type": "application/json",
      token: token
    }

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chatbotEndpoint}/${botId}`
    )

    this.chatbotDetailCallId= reqMsg.messageId
    this.send(reqMsg)
  }

  fetchInitialUserChats = () => {
    this.setState({chatLoading: true})

    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))
    const botId = this.props.navigation.getParam("navigationBarTitleText")

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.initialUserChats}?chatbot_id=${botId}`
    )

    this.userChatCallId= reqMsg.messageId
    this.send(reqMsg)
  }

  handleFetchNextCards = (cardId: number, value: string) => {
    const botId = this.props.navigation.getParam("navigationBarTitleText")
    const newMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }
    newMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )
    newMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userChats}?chatbot_id=${botId}&card_id=${cardId}&next_value=${value}`
    )
    newMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    this.userChatCallId = newMsg.messageId
    this.send(newMsg)
  }

  handleChatbotDetailResponse = (message: Message) => {
    const chatbotResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(chatbotResponse.data) {
      const generalSettings = chatbotResponse.general_setting as GeneralSettings
      const designSettings = chatbotResponse.design_setting as DesignSettings

      const { when_should_bot_initiate_chat, what_user_action_should_the_bot_allow, response_interval } = generalSettings.data.attributes

      this.initiateBotChat(when_should_bot_initiate_chat)
      const {widget_type, widget_url, header_color, background_color, label} = designSettings.data.attributes
      this.setState({
        userAction: what_user_action_should_the_bot_allow.device,
        widgetType: widget_type,
        botIcon: widget_url,
        chatHeaderBackgroundColor: header_color,
        chatHeaderLabel: label,
        chatBackgroundColor: background_color,
        resonseInterval: response_interval ?? 0,
        botName: chatbotResponse.data.attributes.name
      })
    }
  }

  handleInitialUserChatsResponse = (message: Message) => {
    const chatbotResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({chatLoading: false})
    if(!chatbotResponse || chatbotResponse.error) {
      this.setState({hasErrorInChatResponse: true})
      return;
    }

    if(chatbotResponse.user_chats) {
      const newBotDetailMap = new Map<number, ChatResponse>(this.state.botChatDetailMap)
      const userChats = chatbotResponse.user_chats as ChatData[]
      const cards: ChatMessages[] = userChats.map((chat: ChatData) => {
        const {response} = chat.attributes
        const newResponse: ChatResponse =  {
          id: response.id,
          message: response.message,
          video_link: response.video_link,
          card_type: response.card_type,
          card_wait_time: response.card_wait_time,
          option: response.option,
          maximum_selection: response.maximum_selection,
          minimum_selection: response.minimum_selection,
          connector_card_links: response.connector_card_links,
          embeded_forms: response.embeded_forms,
          user_guides: response.user_guides,
          video_data: response.video_data,
        }
        newBotDetailMap.set(response.id, newResponse)
        return {
          id: response.id,
          message: response.message,
          cardType: response.card_type,
        }
      })

      const newChatMessageLists: ChatMessageList[] = [
        ...this.state.chatMessageLists,
        {
          sender: "bot",
          messages: cards
        }
      ]

      this.setState({chatMessageLists: newChatMessageLists, botChatDetailMap: newBotDetailMap}, () => this.scrollToBottom())
    }
  }

  setHeaderAndBodyBackgroundColor = () => {
    const chatPopupElement = document.getElementById("chat-popup")
    if(chatPopupElement) {
      chatPopupElement.style.setProperty("--header-background", this.state.chatHeaderBackgroundColor)
      chatPopupElement.style.setProperty("--body-background", this.state.chatBackgroundColor)
    }

    if(this.state.isChatOpen) {
      this.fetchInitialUserChats()
    }
  }

  initiateBotChat = (botInitiateChat: GeneralSettings["data"]["attributes"]["when_should_bot_initiate_chat"]) => {
    if(window.innerWidth <= 768) {
      const mobileChatInitiateText = botInitiateChat.mobile[0]
      this.setState({isChatOpen: mobileChatInitiateText === visitorsLandOnSite}, () => this.setHeaderAndBodyBackgroundColor())
    } else {
      const desktopChatInitiateText = botInitiateChat.desktop[0]
      this.setState({isChatOpen: desktopChatInitiateText === visitorsLandOnSite}, () => this.setHeaderAndBodyBackgroundColor())
    }
  }

  toggleChat = () => {
    this.setState({isChatOpen: !this.state.isChatOpen}, () => this.setHeaderAndBodyBackgroundColor())
  }

  onUserInputConfirm = (cardId: number, value: string | number | string[]) => {
    const newChatMessagesList: ChatMessageList[] = [...this.state.chatMessageLists]
    const message = Array.isArray(value) ? value.join(", ") : `${value}`
    newChatMessagesList[newChatMessagesList.length - 1].reset = false
    newChatMessagesList.push({
      sender: "user",
      messages: [{
        id: Date.now(),
        message: message,
      }]
    })
    this.setState({chatMessageLists: newChatMessagesList, chatLoading: true})
    this.handleFetchNextCards(cardId, message)
  }

  onFormSubmitConfirm = (cardId: number, questionsWithAnswer: Array<FormQuestionWithAnswer>) => {
    const newChatMessagesList: ChatMessageList[] = [...this.state.chatMessageLists]
    newChatMessagesList[newChatMessagesList.length - 1].reset = false
    newChatMessagesList.push({
      sender: "user",
      messages: [{
        id: Date.now(),
        message: "",
        formQuestionsWithAnswers: questionsWithAnswer
      }]
    })
    this.setState({chatMessageLists: newChatMessagesList, chatLoading: true})
    this.handleFetchNextCards(cardId, "")
  }

  onRetryClick = () => {
    this.setState({chatMessageLists: [], chatLoading: true, hasErrorInChatResponse: false})
    this.fetchInitialUserChats()
  }

  onGoBack = () => {
    const slicedChatMessageLists = this.state.chatMessageLists.slice(0, this.state.chatMessageLists.length - 2)

    const slicedChatListLength = slicedChatMessageLists.length

    slicedChatMessageLists[slicedChatListLength - 1] = {
      ...slicedChatMessageLists[slicedChatListLength - 1],
      reset: true
    }

    this.setState({chatMessageLists: slicedChatMessageLists})
  }

  disableGoBackButton = () => {
    const isUserMssageExist = this.state.chatMessageLists.find((chat) => chat.sender === "user")
    return !isUserMssageExist
  }
}
// Customizable Area End
