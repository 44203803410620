import React, { useState } from "react";
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from "reactflow";
import { getStorageData } from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { CriteriaRouting } from "../../blocks/cfconnectorlogic/src/CfconnectorlogicController";
import { Tooltip, makeStyles } from "@material-ui/core";

type SmoothEdgeData = {
    strokeStyle?: "dashed" | "solid";
    hasError?: boolean;
    rule?: CriteriaRouting
}

const useStyles = makeStyles({
    ruleName: {
        padding: "4px 16px",
        backgroundColor: "#51ABB3CC",
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: 100,
        fontFamily: "Cairo",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "24px",
        color: "#FFFFFF"
    },
    ruleDetailContainer: {
        boxSizing: "border-box",
        padding: 16,
        borderRadius: 8,
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: "#FFFFFF",
        minWidth: 177,
        maxWidth: 250,
        gap: 8,
        display: "flex",
        flexDirection: "column",
    },
    ruleTitle: {
        fontFamily: "Cairo",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "32px",
        color: "#0F172A",
    },
    criteriaDetail: {
        '& > p': {
            display: "flex",
            gap: "4px 8px",
            flexWrap: "wrap",
            margin: 0,
            fontFamily: "Cairo",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "22px",
            color: "#51ABB3",
            '& > span': {
                color: "#0F172A",
            },
        },
    },
    notMatched: {
        backgroundColor: "#2e457b",
    },
})

const criteriaOptionObj: { [Key: string]: string } = {
    operating_system: "Operating system",
    time_of_the_day: "Time of the day",
    current_page_url: "Current page URL",
    bot_contexts: "Context variable",
}

const operatingSystemsObj: { [OperatingKey: string]: string } = {
    "android": "Android",
    "blackberry": "Blackberry",
    "iOS": "iOS",
    "iPadOS": "iPadOS",
    "linux": "Linux",
    "windows": "Windows",
    "nokia": "Nokia"
}

const comparatorsObj: { [ComparatorKey: string]: string } = {
    "is_equal_to": "is",
    "is_not_equal_to": "is not",
    "contains": "contains",
    "between": "between",
    "after": "after",
    "before": "before"
}

const getCriteriaValue = (criteria: CriteriaRouting["rules"][number]) => {
    let value: string = ""
    if (criteria.field_name === "operating_system") {
        value = criteria.values.map((systemVal) => operatingSystemsObj[systemVal]).join(", ")
    }

    if (criteria.field_name === "time_of_the_day") {
        value = criteria.values.join(" and ")
    }

    if (criteria.field_name === "current_page_url") {
        value = criteria.values[0]
    }

    if(criteria.field_name === "bot_contexts") {
        value = criteria.values.join(", ")
    }
    return value
}

const SmoothDashEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style,
    markerEnd,
    data,
    source,
    target,
}: EdgeProps<SmoothEdgeData>) => {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        borderRadius: 25,
    })

    const classes = useStyles()

    const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false)

    const onDeleteEdge = async () => {
        const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
        const header = {
            token,
            "Content-Type": "application/json"
        }
        const body: Record<string, unknown> = {
            card_id: target,
            connector_card_ids: +source
        }

        if(data?.rule) [
            body["previous_card_id"] = data.rule.criteria_routing_id
        ]

        const newReq = new Message(getName(MessageEnum.RestAPIRequestMessage))
        newReq.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        newReq.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        newReq.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        )
        newReq.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_chatbot6/remove_source"
        )
        newReq.addData(
            getName(MessageEnum.NavigationPropsMessage),
            { title: "RemoveSourceFromConnectorCardData", criteriaRoutingId: data?.rule?.criteria_routing_id, cardId: +source }
        )
        runEngine.sendMessage(newReq.id, newReq)
    }

    const renderRuleDetail = (rules: CriteriaRouting["rules"], title: string) => {
        return (
            <div className={classes.ruleDetailContainer} >
                <div className={classes.ruleTitle} >{title}</div>
                {
                    rules.map((criteria, index) => (
                        <div key={index} className={classes.criteriaDetail} >
                            <p>
                                {criteriaOptionObj[criteria.field_name]}
                                {criteria.sub_field ? ` ${criteria.sub_field}` : ""}
                                <span>{`${comparatorsObj[criteria.comparator]} ${getCriteriaValue(criteria)}`}</span>
                            </p>
                        </div>
                    ))
                }
            </div>
        )
    }

    let edgeStyle: React.CSSProperties = {
        strokeWidth: 2,
        stroke: "#334155"
    };

    if (data?.strokeStyle === "dashed") {
        edgeStyle = {
            ...edgeStyle,
            stroke: "#51ABB3",
            strokeDasharray: "5",
            strokeLinecap: "round",
        }
    }

    if (data?.hasError) {
        edgeStyle.stroke = "#DC2626"
    }

    return (
        <g style={{ position: "relative" }} onMouseEnter={() => setShowDeleteIcon(true)} onMouseLeave={() => setShowDeleteIcon(false)} >
            <BaseEdge interactionWidth={10} labelX={labelX} labelY={labelY} path={edgePath} markerEnd={markerEnd} style={{ ...edgeStyle, ...style }} />
            <EdgeLabelRenderer>
                {
                    data?.strokeStyle === "solid" && source !== "start" && showDeleteIcon && (
                        <div
                            style={{
                                position: 'absolute',
                                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                                pointerEvents: 'all',
                                zIndex: 5
                            }}
                            className="nodrag"
                        >
                            <button
                                style={{ all: "unset", cursor: "pointer" }}
                                onClick={onDeleteEdge}
                            >
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_14516_11937)">
                                        <rect x="8" y="6" width="36" height="36" rx="18" fill="#DC2626" />
                                        <path d="M33 33L26 26L23.65 28.35C23.7833 28.6 23.875 28.8667 23.925 29.15C23.975 29.4333 24 29.7167 24 30C24 31.1 23.6083 32.0417 22.825 32.825C22.0417 33.6083 21.1 34 20 34C18.9 34 17.9583 33.6083 17.175 32.825C16.3917 32.0417 16 31.1 16 30C16 28.9 16.3917 27.9583 17.175 27.175C17.9583 26.3917 18.9 26 20 26C20.2833 26 20.5667 26.025 20.85 26.075C21.1333 26.125 21.4 26.2167 21.65 26.35L24 24L21.65 21.65C21.4 21.7833 21.1333 21.875 20.85 21.925C20.5667 21.975 20.2833 22 20 22C18.9 22 17.9583 21.6083 17.175 20.825C16.3917 20.0417 16 19.1 16 18C16 16.9 16.3917 15.9583 17.175 15.175C17.9583 14.3917 18.9 14 20 14C21.1 14 22.0417 14.3917 22.825 15.175C23.6083 15.9583 24 16.9 24 18C24 18.2833 23.975 18.5667 23.925 18.85C23.875 19.1333 23.7833 19.4 23.65 19.65L36 32V33H33ZM29 23L27 21L33 15H36V16L29 23ZM20 20C20.55 20 21.0208 19.8042 21.4125 19.4125C21.8042 19.0208 22 18.55 22 18C22 17.45 21.8042 16.9792 21.4125 16.5875C21.0208 16.1958 20.55 16 20 16C19.45 16 18.9792 16.1958 18.5875 16.5875C18.1958 16.9792 18 17.45 18 18C18 18.55 18.1958 19.0208 18.5875 19.4125C18.9792 19.8042 19.45 20 20 20ZM26 24.5C26.1333 24.5 26.25 24.45 26.35 24.35C26.45 24.25 26.5 24.1333 26.5 24C26.5 23.8667 26.45 23.75 26.35 23.65C26.25 23.55 26.1333 23.5 26 23.5C25.8667 23.5 25.75 23.55 25.65 23.65C25.55 23.75 25.5 23.8667 25.5 24C25.5 24.1333 25.55 24.25 25.65 24.35C25.75 24.45 25.8667 24.5 26 24.5ZM20 32C20.55 32 21.0208 31.8042 21.4125 31.4125C21.8042 31.0208 22 30.55 22 30C22 29.45 21.8042 28.9792 21.4125 28.5875C21.0208 28.1958 20.55 28 20 28C19.45 28 18.9792 28.1958 18.5875 28.5875C18.1958 28.9792 18 29.45 18 30C18 30.55 18.1958 31.0208 18.5875 31.4125C18.9792 31.8042 19.45 32 20 32Z" fill="white" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_14516_11937" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="2" />
                                            <feGaussianBlur stdDeviation="4" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14516_11937" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14516_11937" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                    )
                }

                {
                    data?.rule && (
                        <div
                            style={{
                                position: 'absolute',
                                transform: `translate(-75%, -50%) translate(${labelX}px,${labelY}px)`,
                                pointerEvents: 'all',
                                zIndex: 3
                            }}
                            className="nodrag"
                        >
                            {
                                data.rule.title === "Not Matched" ? (
                                    <div className={`${classes.ruleName} ${classes.notMatched}`} >{data.rule.title}</div>
                                ) : (
                                    <Tooltip
                                        interactive
                                        placement="top-start"
                                        title={renderRuleDetail(data.rule.rules, data.rule.title)}
                                    >
                                        <div className={`${classes.ruleName} ${data.rule.title === "Not Matched" ? classes.notMatched : ""}`} >{data.rule.title}</div>
                                    </Tooltip>
                                )

                            }

                        </div>
                    )
                }
            </EdgeLabelRenderer>
        </g >
    )
}

export default SmoothDashEdge
