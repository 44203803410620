import React from "react";
import { theme as styledTheme } from "../theme.web";
import { styled, createTheme, ThemeProvider, Typography } from "@material-ui/core";
import { callbacks, drawPlugin } from "../../../dashboard4/src/components/Dougnut";
import { Doughnut } from "react-chartjs-2";
import { MenuData } from "../ProjectPortfolioDetail.web";

const { StyledRow, Column } = styledTheme

const settingsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#475569",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Cairo",
      },
    },
    MuiSwitch: {
      input: {
        left: 0,
        width: 48,
        height: 28,
      },
      root: {
        borderRadius: 40,
        padding: 0,
        width: 48,
        height: 28,
      },
      thumb: {
        width: 24,
        height: 24,
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 8px 0px #00000014",
      },
      switchBase: {
        padding: 0,
        left: 2,
        top: 2,
        '&.Mui-checked': {
          '& input': {
            left: "-24px"
          },
        },
      },
      colorSecondary: {
        '&.Mui-checked + $track': {
          backgroundColor: "#51ABB3",
          opacity: 1,
        },
      },
      track: {
        backgroundColor: "#E2E8F0",
        opacity: 1,
      },
    },
  },
})

const SettingsContainer = styled("div")({
  width: 416,
  boxSizing: "border-box",
  padding: "24px 40px",
  backgroundColor: "#FFFFFF",
  display: 'flex',
  flexDirection: "column",
  gap: "12px",
  boxShadow: "0px 2px 8px 0px #00000014",
  '& .makeStyles-root-72': {
    width: "100% !important"
  },
  '& h5': {
    margin: 0,
    fontFamily: "Cairo",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "26px",
    color: "#0F172A",
    marginBottom: 12,
  },
  '& .manageBtn': {
    width: "57px",
    height: "24px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Cairo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#51ABB3",
    background: "#fff",
    border: "none"
  }
})
const chartData = [{
  id: 1, title: "User Guides", icon: <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666992 14C0.666992 6.63996 6.62699 0.666626 13.987 0.666626C21.3603 0.666626 27.3337 6.63996 27.3337 14C27.3337 21.36 21.3603 27.3333 13.987 27.3333C6.62699 27.3333 0.666992 21.36 0.666992 14ZM9.33366 8.66663C8.22909 8.66663 7.33366 9.56206 7.33366 10.6666C7.33366 11.7712 8.22909 12.6666 9.33366 12.6666C10.4382 12.6666 11.3337 11.7712 11.3337 10.6666C11.3337 9.56206 10.4382 8.66663 9.33366 8.66663ZM18.667 8.66663C17.5624 8.66663 16.667 9.56206 16.667 10.6666C16.667 11.7712 17.5624 12.6666 18.667 12.6666C19.7716 12.6666 20.667 11.7712 20.667 10.6666C20.667 9.56206 19.7716 8.66663 18.667 8.66663ZM14.0003 24.6666C8.10699 24.6666 3.33366 19.8933 3.33366 14C3.33366 8.10663 8.10699 3.33329 14.0003 3.33329C19.8937 3.33329 24.667 8.10663 24.667 14C24.667 19.8933 19.8937 24.6666 14.0003 24.6666ZM18.507 16.84C18.787 16.3733 19.4137 16.2266 19.8803 16.52C20.347 16.8133 20.4937 17.4266 20.2003 17.8933C18.8403 20.04 16.5337 21.3333 14.0003 21.3333C11.467 21.3333 9.16033 20.0533 7.80032 17.88C7.50699 17.4133 7.65366 16.8 8.12033 16.5066C8.58699 16.2133 9.20033 16.36 9.49366 16.8266C10.4803 18.4 12.1603 19.3333 14.0003 19.3333C15.8403 19.3333 17.5203 18.4 18.507 16.84Z" fill="#059669" />
  </svg>
},
{
  id: 2, title: "Forms", icon: <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.987 0.666626C6.62699 0.666626 0.666992 6.63996 0.666992 14C0.666992 21.36 6.62699 27.3333 13.987 27.3333C21.3603 27.3333 27.3337 21.36 27.3337 14C27.3337 6.63996 21.3603 0.666626 13.987 0.666626ZM7.33366 10.6666C7.33366 9.56206 8.22909 8.66663 9.33366 8.66663C10.4382 8.66663 11.3337 9.56206 11.3337 10.6666C11.3337 11.7712 10.4382 12.6666 9.33366 12.6666C8.22909 12.6666 7.33366 11.7712 7.33366 10.6666ZM18.667 8.66663C17.5624 8.66663 16.667 9.56206 16.667 10.6666C16.667 11.7712 17.5624 12.6666 18.667 12.6666C19.7716 12.6666 20.667 11.7712 20.667 10.6666C20.667 9.56206 19.7716 8.66663 18.667 8.66663ZM10.0003 17.6666C10.0003 18.2133 10.4537 18.6666 11.0003 18.6666H17.0003C17.547 18.6666 18.0003 18.2133 18.0003 17.6666C18.0003 17.12 17.547 16.6666 17.0003 16.6666H11.0003C10.4537 16.6666 10.0003 17.12 10.0003 17.6666ZM3.33366 14C3.33366 19.8933 8.10699 24.6666 14.0003 24.6666C19.8937 24.6666 24.667 19.8933 24.667 14C24.667 8.10663 19.8937 3.33329 14.0003 3.33329C8.10699 3.33329 3.33366 8.10663 3.33366 14Z" fill="#F59E0B" />
  </svg>
}, {
  id: 3, title: "Q&As", icon: <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666992 14C0.666992 6.63996 6.62699 0.666626 13.987 0.666626C21.3603 0.666626 27.3337 6.63996 27.3337 14C27.3337 21.36 21.3603 27.3333 13.987 27.3333C6.62699 27.3333 0.666992 21.36 0.666992 14ZM9.33366 8.66663C8.22909 8.66663 7.33366 9.56206 7.33366 10.6666C7.33366 11.7712 8.22909 12.6666 9.33366 12.6666C10.4382 12.6666 11.3337 11.7712 11.3337 10.6666C11.3337 9.56206 10.4382 8.66663 9.33366 8.66663ZM18.667 8.66663C17.5624 8.66663 16.667 9.56206 16.667 10.6666C16.667 11.7712 17.5624 12.6666 18.667 12.6666C19.7716 12.6666 20.667 11.7712 20.667 10.6666C20.667 9.56206 19.7716 8.66663 18.667 8.66663ZM14.0003 24.6666C8.10699 24.6666 3.33366 19.8933 3.33366 14C3.33366 8.10663 8.10699 3.33329 14.0003 3.33329C19.8937 3.33329 24.667 8.10663 24.667 14C24.667 19.8933 19.8937 24.6666 14.0003 24.6666ZM7.80032 20.1066C9.16033 17.96 11.467 16.6666 14.0003 16.6666C16.5337 16.6666 18.8403 17.9466 20.2137 20.1066C20.507 20.5733 20.3603 21.1866 19.8937 21.48C19.7203 21.5733 19.547 21.6266 19.3603 21.6266C19.027 21.6266 18.6937 21.4666 18.507 21.16C17.5203 19.5866 15.8403 18.6533 14.0003 18.6533C12.1603 18.6533 10.4803 19.5866 9.49366 21.16C9.20033 21.6266 8.58699 21.7733 8.12033 21.48C7.65366 21.1866 7.50699 20.5733 7.80032 20.1066Z" fill="#007AFF" />
  </svg>
},
{
  id: 4, title: "Signposting", icon: <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666992 14C0.666992 6.63996 6.62699 0.666626 13.987 0.666626C21.3603 0.666626 27.3337 6.63996 27.3337 14C27.3337 21.36 21.3603 27.3333 13.987 27.3333C6.62699 27.3333 0.666992 21.36 0.666992 14ZM9.33366 8.66663C8.22909 8.66663 7.33366 9.56206 7.33366 10.6666C7.33366 11.7712 8.22909 12.6666 9.33366 12.6666C10.4382 12.6666 11.3337 11.7712 11.3337 10.6666C11.3337 9.56206 10.4382 8.66663 9.33366 8.66663ZM18.667 8.66663C17.5624 8.66663 16.667 9.56206 16.667 10.6666C16.667 11.7712 17.5624 12.6666 18.667 12.6666C19.7716 12.6666 20.667 11.7712 20.667 10.6666C20.667 9.56206 19.7716 8.66663 18.667 8.66663ZM14.0003 24.6666C8.10699 24.6666 3.33366 19.8933 3.33366 14C3.33366 8.10663 8.10699 3.33329 14.0003 3.33329C19.8937 3.33329 24.667 8.10663 24.667 14C24.667 19.8933 19.8937 24.6666 14.0003 24.6666ZM18.507 16.84C18.787 16.3733 19.4137 16.2266 19.8803 16.52C20.347 16.8133 20.4937 17.4266 20.2003 17.8933C18.8403 20.04 16.5337 21.3333 14.0003 21.3333C11.467 21.3333 9.16033 20.0533 7.80032 17.88C7.50699 17.4133 7.65366 16.8 8.12033 16.5066C8.58699 16.2133 9.20033 16.36 9.49366 16.8266C10.4803 18.4 12.1603 19.3333 14.0003 19.3333C15.8403 19.3333 17.5203 18.4 18.507 16.84Z" fill="#059669" />
  </svg>
},
]

const chartSetting = {
  borderRadius: "8px"
};

export default function TrainingHubSideContent({ fileSizes }: { fileSizes: any }) {
  const total: number[] = [];
  const percentages: number[] = [];
  const sizes: number[] = [];

  for (const key in fileSizes) {
    if (fileSizes.hasOwnProperty(key)) {
      total.push(fileSizes[key].total);
      sizes.push(fileSizes[key].size);
      percentages.push(parseFloat(fileSizes[key].percentage))
    }
  }
  const data = {
    labels: chartData.map(data => data.title),
    datasets: [{
      label: 'My First Dataset',
      data: percentages,
      backgroundColor: [
        '#3CC2E0',
        '#34D399',
        '#A666FF',
        '#F59E0B',
      ],
      hoverOffset: 4,
    }]
  };
  return (
    <ThemeProvider theme={settingsTheme} >
      <SettingsContainer style={{ gap: "8px" }}>
        <h5>Storage</h5>
        <div style={{ width: "240px", height: "240px" }}>
          <Doughnut data={data} plugins={[drawPlugin]} options={{
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: true,
                backgroundColor: '#F1F5F9',
                bodyColor: '#F1F5F9',
                borderColor: '#F1F5F9',
                borderWidth: 1,
                titleFont: { weight: 'bold' },
                callbacks: callbacks(data)
              },
              datalabels: {
                display: false,
              },
            },
            rotation: -90,
            offset: 4,
            circumference: 360,
            cutout: "67%",
            maintainAspectRatio: true,
            responsive: true
          }} {...chartSetting} />
        </div>
        <Column style={{ gap: "24px", marginTop: "12px" }}>
          {MenuData.slice(1,).map((data, indx) => (
            <Tab style={{ gap: "8px" }}>
              <div style={{ borderRadius: "8px", border: "1px solid #E2E8F0", padding: "10px" }} key={data.id}>
                {data.svg}
              </div>
              <StyledRow className="space-between" style={{ width: "100%" }}>
                <div>
                  <Typography component={"h1"}>{data.title}</Typography>
                  <Typography component={"h6"}>{total[indx]} Files</Typography>
                </div>
                <Typography component={"h6"} style={{ color: "#64748B" }}>{sizes[indx]}</Typography>
              </StyledRow>
            </Tab>
          ))}
        </Column>
      </SettingsContainer>
    </ThemeProvider>
  )
}

const Tab = styled("div")({
  display: "flex",
  alignItems: "center",

  "& h1": {
    color: "#334155",
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: "Cairo",
    lineHeight: "22px",
    textTransform: "capitalize"
  },
  "& h6": {
    color: "#475569",
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: "Cairo",
    lineHeight: "18px",
    textTransform: "capitalize"
  },
})