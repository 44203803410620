import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"
import { toast } from "react-toastify";
import { utils } from "./utils.web";

type Option = {label: string, value: string};
export type CroppedImage = {
  url: string; 
  file: File;
}

export type GeneralSettingsState = {
  chatInitiateOnMobile: string;
  chatInitiateOnDesktop: string;
  responseInterval: string;
  userAction: string[];
  chatbotURL: string;
  qrCodeImageFile: File | null;
  qrCodeImageURL: string | null;
  shouldUseOpenAI: boolean;
}

type UpdateRequestMessageArgs = {
  formData: FormData;
  endpoint: string;
  type: "general" | "design"
}

type Organization = {
  id: number;
  organisation_name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedPracticeValue: string;
  headerColor: string;
  backgroundColor: string;
  widget: string;
  headerLabel: string;
  headerImage: File | null;
  headerImageURL: string | null;
  chatInitiateOnMobile: string;
  chatInitiateOnDesktop: string;
  responseInterval: string;
  userAction: string[];
  chatbotURL: string;
  qrCodeImageFile: File | null;
  qrCodeImageURL: string | null;
  shouldUseOpenAI: boolean;
  token: string;
  practices: Option[];
  chatbotName: string | null;
  loading: boolean;
  activeTab: "general" | "design";
  isUpdateSettingsLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customiseinterface2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  intialHeaderLabelValue: string = "Max Support Chatbot";
  initialPracticeAssociation: string = "";
  initialHeaderColor: string = "#51ABB3";
  initialBackgroundColor: string = "#FFFFFF";
  initialWidget: string = "chat";
  initiaHeaderImageURL: string | null = null;
  initialResponseInterval = "2";
  initialChatInitiateOnMobile = "";
  initialChatInitiateOnDesktop = "";
  initialUserAction: string[] = [];
  initialQRCodeImageFile = null;
  initialQRCodeImageURL = null;
  initialChatbotURL = "https://";
  initialShouldUseOpenAI = false;
  practiceCallId: string | null = null;
  generalSettingsCallId: string | null = null;
  designSettingsCallId: string | null = null;
  generalSettingsUpdateCallId: string | null = null;
  designSettingsUpdateCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedPracticeValue: "",
      headerColor: "#51ABB3",
      backgroundColor: "#FFFFFF",
      widget: "chat",
      headerLabel: "",
      headerImage: null,
      headerImageURL: null,
      chatInitiateOnMobile: "",
      chatInitiateOnDesktop: "",
      responseInterval: "2",
      userAction: [],
      chatbotURL: "https://",
      qrCodeImageFile: null,
      qrCodeImageURL: null,
      shouldUseOpenAI: false,
      token: "",
      practices: [],
      chatbotName: null,
      loading: true,
      activeTab: "general",
      isUpdateSettingsLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleFetchPracticeData = this.handleFetchPracticeData.bind(this)
    this.handlePracticeDataResponse = this.handlePracticeDataResponse.bind(this)
    this.handleFetch = this.handleFetch.bind(this)
    this.handleFetchGeneralSettings = this.handleFetchGeneralSettings.bind(this)
    this.handleGeneralSettingsResponse = this.handleGeneralSettingsResponse.bind(this)
    this.handleDesignSettingsResponse = this.handleDesignSettingsResponse.bind(this)
    this.handleUpdateDesignSettingsResponse = this.handleUpdateDesignSettingsResponse.bind(this)
    this.handleUpdateGeneralSettingsResponse = this.handleUpdateGeneralSettingsResponse.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      await this.handleRestApiResponse(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token: token})
    this.handleFetchGeneralSettings(token)
    this.handleFetchPracticeData(token)
    this.handleFetchDesignSettings(token)
  }

  async handleRestApiResponse(message: Message) {
    const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    if(this.practiceCallId !== null && this.practiceCallId === responseId) {
      responseHandlers[this.practiceCallId] = this.handlePracticeDataResponse
    }

    if(this.designSettingsCallId !== null && this.designSettingsCallId === responseId) {
      responseHandlers[this.designSettingsCallId] = this.handleDesignSettingsResponse
    }

    if(this.generalSettingsCallId !== null && this.generalSettingsCallId === responseId) {
      responseHandlers[this.generalSettingsCallId] = this.handleGeneralSettingsResponse
    }

    if(this.designSettingsUpdateCallId !== null && this.designSettingsUpdateCallId === responseId) {
      responseHandlers[this.designSettingsUpdateCallId] = this.handleUpdateDesignSettingsResponse
    }

    if(this.generalSettingsUpdateCallId !== null && this.generalSettingsUpdateCallId === responseId) {
      responseHandlers[this.generalSettingsUpdateCallId]= this.handleUpdateGeneralSettingsResponse
    }

    const responseHandler = responseHandlers[responseId];
    if (responseHandler) {
      responseHandler(message);
    }
  }

  async handleUpdateDesignSettingsResponse(message: Message) {
    this.setState({isUpdateSettingsLoading: false})
    const designSettingsUpdateResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!designSettingsUpdateResponse) {
      toast.error("Failed to update design settings", {className: "error__toast"})
      return;
    }

    if(designSettingsUpdateResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }
    if(designSettingsUpdateResponse.data) {
      toast.success("Design settings successfully updated!", {className: "success__toast"})
    }
  }

  async handleUpdateGeneralSettingsResponse(message: Message) {
    const generalSettingsUpdateResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({isUpdateSettingsLoading: false})
    if(!generalSettingsUpdateResponse) {
      toast.error("Failed to update general settings", {className: "error__toast"})
      return;
    }

    if(generalSettingsUpdateResponse.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }
    if(generalSettingsUpdateResponse.data) {
      toast.success("General settings successfully updated!", {className: "success__toast"})
    }
  }

  async handlePracticeDataResponse(message: Message) {
    const practiceResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(!practiceResponse) {
      toast.error("Failed to fetch!", {className: "error__toast"})
      return;
    }
    if(practiceResponse?.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }
    if(practiceResponse.organizations) {
      const newPractices: Option[] = practiceResponse.organizations.map((item: Organization) => ({label: item.organisation_name, value: `${item.id}`}))
      this.setState({practices: newPractices})
    }
  }

  async handleDesignSettingsResponse(message: Message) {
    const designSettingsResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!designSettingsResponse) {
      toast.error("Failed to fetch design settings!", {className: "error__toast"})
      return;
    }
    
    if(designSettingsResponse.data) {
      const {attributes} = designSettingsResponse.data
      this.initialHeaderColor = attributes.header_color 
      this.initialBackgroundColor = attributes.background_color 
      this.intialHeaderLabelValue = attributes.label 
      this.initiaHeaderImageURL = attributes.widget_url
      this.initialPracticeAssociation = `${attributes.practice_association}`
      this.initialWidget = attributes.widget_type ?? "chat"
      this.setState({
        headerColor: attributes.header_color,
        backgroundColor: attributes.background_color,
        headerLabel: attributes.label,
        headerImageURL: attributes.widget_url,
        selectedPracticeValue: `${attributes.practice_association}`,
        widget: attributes.widget_type ?? "chat",
        chatbotName: attributes.chatbot_name
      })
    }
  }

  async handleGeneralSettingsResponse(message: Message) {
    const generalSettingsResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!generalSettingsResponse) {
      toast.error("Failed to fetch general settings!", {className: "error__toast"})
      return;
    }

    if(generalSettingsResponse.data) {
      const {attributes} = generalSettingsResponse.data; 
      this.initialPracticeAssociation = `${attributes.practice_association}`;
      this.initialChatInitiateOnMobile =  attributes.when_should_bot_initiate_chat.mobile[0];
      this.initialChatInitiateOnDesktop = attributes.when_should_bot_initiate_chat.desktop[0];
      this.initialUserAction = attributes.what_user_action_should_the_bot_allow.device;
      this.initialResponseInterval = attributes.response_interval ? `${attributes.response_interval}` : "0";
      this.initialChatbotURL = attributes.chatbot_url;
      this.initialShouldUseOpenAI = attributes.edatt;
      this.initialQRCodeImageURL = attributes.qr_code;

      this.setState({
        selectedPracticeValue: `${attributes.practice_association}`,
        chatInitiateOnMobile: attributes.when_should_bot_initiate_chat.mobile[0],
        chatInitiateOnDesktop: attributes.when_should_bot_initiate_chat.desktop[0],
        responseInterval: this.initialResponseInterval,
        userAction: attributes.what_user_action_should_the_bot_allow.device,
        chatbotURL: attributes.chatbot_url,
        shouldUseOpenAI: attributes.edatt,
        qrCodeImageURL: attributes.qr_code
      })
    }
  }

  handleFetchPracticeData(token: string) {
    this.handleFetch(
      token,
      "practice",
      configJSON.practiceDataEndpoint
    )
  }

  handleFetchDesignSettings(token: string) {
    const botId = this.props.navigation.getParam("navigationBarTitleText")
    this.handleFetch(
      token,
      "design",
      `${configJSON.designSettingsEndpoint}/${botId}/show`
    )
  }

  handleFetchGeneralSettings(token: string) {
    const chatbotId = this.props.navigation.getParam("navigationBarTitleText")
    this.handleFetch(
      token,
      "general",
      `${configJSON.generalSettingsEndpoint}/${chatbotId}`
    )
  }

  handleFetch(token: string, type: "practice" | "general" | "design", endpoint: string) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    }

    const newReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    newReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )
    newReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    if(type === "practice") {
      this.practiceCallId = newReqMessage.messageId
    }

    if(type === "design") {
      this.designSettingsCallId = newReqMessage.messageId
    }

    if(type === "general") {
      this.generalSettingsCallId = newReqMessage.messageId
    }

    this.send(newReqMessage)
  }
  
  handlePracticeChange = (value: string) => {
    this.setState({selectedPracticeValue: value})
  }

  handleChangeHeaderColor = (color: string) => {
    this.setState({headerColor: color})
  }

  handleChangeBackgroundColor = (color: string) => {
    this.setState({backgroundColor: color})
  }

  handleWidgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({widget: event.target.value})
  }

  handleHeaderLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({headerLabel: event.target.value})
  }

  handleHeaderImageChange = (image: CroppedImage | null) => {
    this.setState({headerImageURL: image?.url ?? null, headerImage: image?.file ?? null})
  }

  handleCancelAllChanges = () => {
    this.setState({
      selectedPracticeValue: this.initialPracticeAssociation,
      headerColor: this.initialHeaderColor,
      backgroundColor: this.initialBackgroundColor,
      widget: this.initialWidget,
      headerLabel: this.intialHeaderLabelValue,
      headerImage: null,
      headerImageURL: this.initiaHeaderImageURL,
      chatInitiateOnMobile: this.initialChatInitiateOnMobile,
      chatInitiateOnDesktop: this.initialChatInitiateOnDesktop,
      responseInterval: this.initialResponseInterval,
      userAction: this.initialUserAction,
      chatbotURL: this.initialChatbotURL,
      qrCodeImageFile: this.initialQRCodeImageFile,
      qrCodeImageURL: this.initialQRCodeImageURL,
      shouldUseOpenAI: this.initialShouldUseOpenAI,
    })
  }

  handleGeneralSettingsStateChange = (value: GeneralSettingsState) => {
    this.setState(value)
  }

  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()

    const navigationMessage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(navigationMessage)
  }

  handleNavigateToSpecificBotPage = () => {
    const message = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.props.navigation.getParam("navigationBarTitleText")
    )
    this.send(message)
  }

  handleNavigateToBotsage = () => {
    const message = new Message(getName(MessageEnum.NavigationBotsMessage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.props.navigation.getParam("navigationBarTitleText")
    )
    this.send(message)
  }

  handleChangeTab = (currentTab: "general" | "design") => () => this.setState({activeTab: currentTab})

  handleUpdateRequestMessage = (args: UpdateRequestMessageArgs) => {
    const header = {
      token: this.state.token,
    }

    this.setState({isUpdateSettingsLoading: true})

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      args.formData
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      args.endpoint
    )

    if(args.type === "general") {
      this.generalSettingsUpdateCallId = reqMessage.messageId
    } else {
      this.designSettingsUpdateCallId = reqMessage.messageId
    }
    this.send(reqMessage)
  }

  handleUpdateGeneralSettings = () => {
    const error = utils.getGeneralSettingsError({
      selectedPracticeValue: this.state.selectedPracticeValue,
      userAction: this.state.userAction,
      chatbotURL: this.state.chatbotURL,
      qrCodeImageURL: this.state.qrCodeImageURL
    })

    if(error) {
      toast.error(error, {className: "error__toast"})
      return;
    }

    const formdata = new FormData();
    formdata.append("practice_association", `${this.state.selectedPracticeValue}`);
    formdata.append("when_should_bot_initiate_chat[mobile][]", this.state.chatInitiateOnMobile);
    formdata.append("when_should_bot_initiate_chat[desktop][]", this.state.chatInitiateOnDesktop);
    formdata.append("response_interval", this.state.responseInterval);
    if(this.state.userAction.includes("go_back_to_previous_message")) {
      formdata.append("what_user_action_should_the_bot_allow[device][]", "go_back_to_previous_message");
    }
    if(this.state.userAction.includes("restart_conversation")) {
      formdata.append("what_user_action_should_the_bot_allow[device][]", "restart_conversation");
    }
    formdata.append("chatbot_url", this.state.chatbotURL);
    if(this.state.qrCodeImageFile) {
      formdata.append("qr_code", this.state.qrCodeImageFile)
    }
    formdata.append("chatbot_id", `${this.props.navigation.getParam("navigationBarTitleText")}`);
    formdata.append("edatt", `${this.state.shouldUseOpenAI}`);
  
    this.handleUpdateRequestMessage({
      endpoint: `${configJSON.generalSettingsUpdateEndpoint}/${this.props.navigation.getParam("navigationBarTitleText")}`,
      type: "general",
      formData: formdata
    })
  }

  handleUpdateDesignSettings = () => {
    const error = utils.getDesignSettingsError({
      selectedPracticeValue: this.state.selectedPracticeValue,
      headerLabel: this.state.headerLabel,
    })

    if(error) {
      toast.error(error, {className: "error__toast"})
      return;
    }
    const formData = new FormData()
    formData.append("customise_interface[header_color]", this.state.headerColor)
    formData.append("customise_interface[background_color]", this.state.backgroundColor)
    formData.append("customise_interface[label]", this.state.headerLabel)
    if(this.state.headerImage) {
      formData.append("customise_interface[widget_url]", this.state.headerImage)
    }
    formData.append("customise_interface[widget_type]", this.state.widget)
    formData.append("practice_association", `${this.state.selectedPracticeValue}`)

    this.handleUpdateRequestMessage({
      endpoint: `${configJSON.designSettingsUpdateEndpoint}/${this.props.navigation.getParam("navigationBarTitleText")}/update`,
      type: "design",
      formData: formData
    })

  }

  handleUpdateSettings = () => {
    if(this.state.activeTab === "general") {
      this.handleUpdateGeneralSettings()
    }
    else {
      this.handleUpdateDesignSettings()
    }
  }
  // Customizable Area End
}
